




























import { Component, Prop, Vue } from "vue-property-decorator";
import toastr from "toastr";
import copy from "copy-text-to-clipboard";

@Component
export default class HelloWorld extends Vue {
  @Prop() private msg!: string;
  searchFor = "";
  loading = false;
  definitions = "";
  oxfordApiLink = "	https://od-api.oxforddictionaries.com/api/v2/";
  creds = { app_id: "b63eb680", app_key: "13ce343b42cdd9428b7a57ea62620541" }; // SAVE INTO ENV VARIABLES
  languageCode = "en-gb";

  get enableSubmit(): boolean {
    return this.searchFor.length === 0 || this.loading;
  }

  get disableCopyButton(): boolean {
    return !this.definitions.length;
  }

  copyMeaningToClipboard(): void {
    copy(this.definitions);
    toastr.success("Copied to clipboard!");
  }

  startSearch(event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      this.searchForWord();
    }
  }

  searchForWord(): void {
    this.loading = true;
    // fetch(`https://api.github.com/users/${this.searchFor}`)
    fetch(
      `https://word-report-svc.herokuapp.com/search?word=${this.searchFor}`
      // `${this.oxfordApiLink}entries/${this.languageCode}/${this.searchFor}`,
      // { headers: { app_id: this.creds.app_id, app_key: this.creds.app_key } }
    )
      .then((res) => {
        res.json().then((content) => {
          console.log(content);
          let allMeanings: { type: string; def: string }[] = [];
          content[0].meanings.forEach((meaning: any) => {
            allMeanings.push({
              type: meaning.partOfSpeech,
              def: meaning.definitions[0].definition,
            });
          });
          this.definitions = JSON.stringify(allMeanings, null, 2);
        });
      })
      .catch((err) => {
        this.definitions = JSON.stringify(err, null, 2);
      })
      .finally(() => {
        this.loading = false;
        // this.
      });
    // console.log(this.searchFor);
  }
}
